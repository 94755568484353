













































































































































































































































































































































































































































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Component, Vue, Watch } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { DossierSearchParams } from "@/api/models/dossiers/dossier";
import { Site } from "@/api/models/options/entreprises/site";
import SearchDossierResults from "./SearchDossierResults.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import {
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormRadioGroup,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BButton,
  BSpinner,
  BFormInput
} from "bootstrap-vue";
import { Region } from "@/api/models/options/entreprises/region"
import { Branche } from "@/api/models/options/entreprises/branche"
import { errorAlert } from "@/libs/sweetAlerts/alerts"
import { SituationTravail } from "@/api/models/options/situationsTravails/situationTravail"
import { Tag } from "@/api/models/options/entreprises/tag"
import { Secteur } from "@/api/models/options/entreprises/secteur"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail"
import { required } from "@validations"
import store from '@/store/index'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import { CategorieInvalidite } from "@/api/models/dossiers/categorieInvalidite"
import { TauxIPP } from "@/api/models/dossiers/tauxIPP"
import { AptitudeAvis } from '../../../api/models/options/situationProfessionnelles/aptitudesAvis'
import { TypeConsommationSearchEnum } from "@/api/models/enums/typeConsommationEnum"
import { TypeVisite } from "@/api/models/options/typesVisite/typeVisite"
import VueSlider from 'vue-slider-component'
import { ContexteEntreprise } from "@/api/models/options/contextesEntreprise/contexteEntreprise"
import { Risque } from "@/api/models/dossiers/risques"


@Component({
  components: {
    SearchableVueSelect,
    SearchDossierResults,
    RessifnetDateInput,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BSpinner,
    BFormInput,
    VueSlider,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem
  },
})
export default class ListeDossiers extends Vue {
  required = required

  optionsTypeConsommation = TypeConsommationSearchEnum
  
  search: DossierSearchParams = {
    createur: false,
    tranchesAge: null,
    anciennete: null,
    sexe: undefined,
    filterSexe: false,
    tagsId: []
  };

  sortBy='numeroDossier'
  isSortDirDesc = false

  pageSize = 25;
  pageNumber = 1;
  searchLoading = false;

  optionsTranchesAge: any = [
    { text: "moins de 30 ans", value: { minimum: null, maximum: 30 } },
    { text: "30 à 45 ans", value: { minimum: 30, maximum: 45 } },
    { text: "45 à 60 ans", value: { minimum: 45, maximum: 60 } },
    { text: "plus de 60 ans", value: { minimum: 60, maximum: null } },
  ];

  optionsAnciennete: any = [
    { text: "moins d'un an", value: { minimum: null, maximum: 1 } },
    { text: "1 à 2 ans", value: { minimum: 1, maximum: 2 } },
    { text: "2 à 5 ans", value: { minimum: 2, maximum: 5 } },
    { text: "5 à 10 ans", value: { minimum: 5, maximum: 10 } },
    { text: "plus de 10 ans", value: { minimum: 10, maximum: null } },
  ];

  listSituationsTravail: PaginatedList<SituationTravail> = new PaginatedList<SituationTravail>();
  listCategorieInvalidites: PaginatedList<CategorieInvalidite> = new PaginatedList<CategorieInvalidite>();
  listTauxIPP: PaginatedList<TauxIPP> = new PaginatedList<TauxIPP>();
  listAptitudesAvis: PaginatedList<AptitudeAvis> = new PaginatedList<AptitudeAvis>();
  listTypesVisite: PaginatedList<TypeVisite> = new PaginatedList<TypeVisite>()
  listContextes: PaginatedList<ContexteEntreprise> = new PaginatedList<ContexteEntreprise>();
  listRisques: PaginatedList<Risque> = new PaginatedList<Risque>();

  searchResult: PaginatedList<any> | null = null;

  listeEntreprises: Entreprise[] =  []
  totalCountEntreprise = 0

  listeSites: Site[] = []
  totalCountSite = 0

  listeSecteur: Secteur[] = []
  totalCountSecteur = 0

  searchParams: any[] = [];

  listeBranches : Branche[] = []
  totalCountBranche = 0

  listeRegions : Region[] = []
  totalCountRegion = 0

  totalCountTag: number = 0;
  listeTags: Tag[] = [];

  searchReferentsTotalCount = 0;
  searchReferentsItems: UserDetail[] = [];

  brancheLibelle = ""
  regionLibelle = ""

  refreshIndex = 0

  countFiltresEntreprise = 0

  entrepriseCollapseTitle = "Entreprise"
  civiliteCollapseTitle = "Civilité"
  droitsStatutCollapseTitle = "Droits, statut et aptitude"
  infosSanteCollapseTitle = "Informations de santé"
  passageInfirmerieCollapseTitle = "Passage à l'infirmerie"
  etudeDePosteCollapseTitle = "Etude de poste"

  get aptitudeAvisSelected() {
    return this.listAptitudesAvis.items.find(x => x.id == this.search.aptitudeId)
  }

  @Watch("search.entrepriseId")
  @Watch("search.siteId")
  @Watch("search.secteurId")
  @Watch("search.regionId")
  @Watch("search.brancheId")
  @Watch("search.anciennete")
  @Watch("search.tagsId")
  @Watch("search.sst")
  refreshEntrepriseCollapseTitle() {
    var title = "Entreprise"
    var count = 0

    if (this.search.entrepriseId)
      count++
    if (this.search.siteId)
      count++
    if (this.search.secteurId)
      count++
    if (this.search.regionId)
      count++
    if (this.search.brancheId)
      count++
    if (this.search.anciennete)
      count++
    if (this.search.tagsId && this.search.tagsId?.length > 0)
      count++
    if (this.search.sst)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.entrepriseCollapseTitle = title
  }

  @Watch("search.nomPersonne")
  @Watch("search.tranchesAge")
  @Watch("search.sexe")
  refreshCiviliteCollapseTitle() {
    var title = "Civilité"
    var count = 0

    if (this.search.nomPersonne)
      count++
    if (this.search.tranchesAge)
      count++
    if (this.search.sexe != undefined && this.search.sexe.length > 0)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.civiliteCollapseTitle = title
  }

  @Watch("search.rqth")
  @Watch("search.echeanceAvant")
  @Watch("search.categorieInvaliditeId")
  @Watch("search.tauxIppId")
  @Watch("search.aptitudeId")
  @Watch("search.restrictionAvant")
  refreshDroitsStatutCollapseTitle() {
    var title = "Droits, statut et aptitude"
    var count = 0

    if (this.search.rqth)
      count++
    if (this.search.rqth && this.search.echeanceAvant)
      count++
    if (this.search.categorieInvaliditeId)
      count++
    if (this.search.tauxIppId)
      count++
    if (this.search.aptitudeId)
      count++
    if (this.search.restrictionAvant && this.search.aptitudeId && this.aptitudeAvisSelected!.isMPA)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.droitsStatutCollapseTitle = title
  }

  @Watch("search.maladiePro")
  @Watch("search.numeroVaccination")
  @Watch("search.tabac")
  @Watch("search.alcool")
  @Watch("search.substance")
  @Watch("search.addictionComportementale")
  refreshInfosSanteCollapseTitle() {
    var title = "Informations de santé"
    var count = 0

    if (this.search.maladiePro)
      count++
    if (this.search.numeroVaccination)
      count++
    if (this.search.tabac && this.search.tabac.length > 0)
      count++
    if (this.search.alcool && this.search.alcool.length > 0)
      count++
    if (this.search.substance)
      count++
    if (this.search.addictionComportementale)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.infosSanteCollapseTitle = title
  }

  @Watch("search.piReceived")
  @Watch("search.typeVisiteIds")
  @Watch("search.piAvant")
  @Watch("search.piApres")
  @Watch("search.piStress")
  @Watch("search.satisfaction")
  refreshPassageInfirmerieCollapseTitle() {
    var title = "Passage à l'infirmerie"
    var count = 0

    if (this.search.piReceived)
      count++
    if (this.search.typeVisiteIds && this.search.typeVisiteIds.length > 0)
      count++
    if (this.search.piAvant)
      count++
    if (this.search.piApres)
      count++
    if (this.search.piStress)
      count++
    if (this.search.satisfaction)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.passageInfirmerieCollapseTitle = title
  }

  @Watch("search.edpReceived")
  @Watch("search.contextesId")
  @Watch("search.risquesId")
  @Watch("search.edpAvant")
  @Watch("search.edpApres")
  @Watch("search.edpStress")
  refreshEtudeDePosteCollapseTitle() {
    var title = "Etude de poste"
    var count = 0

    if (this.search.edpReceived)
      count++
    if (this.search.contextesId && this.search.contextesId.length > 0)
      count++
    if (this.search.risquesId && this.search.risquesId.length > 0)
      count++
    if (this.search.edpAvant)
      count++
    if (this.search.edpApres)
      count++
    if (this.search.edpStress)
      count++

    if (count > 0)
      title += " - " + count + " filtre(s) actif(s)"

    this.etudeDePosteCollapseTitle = title
  }

  async created() {
    if (store.state.listeDossier != undefined) {
      this.search = store.state.listeDossier
      await this.searchDossiers({});    
    }
      

    await this.searchEntreprises({onCreate: true})
    await this.searchSites({onCreate: true})
    await this.searchRegions({onCreate: true})
    await this.searchBranches({onCreate: true})
    await this.searchSecteurs({onCreate: true})
    await this.searchTags({onCreate: true})
    await this.loadListCategoriesInvalidites()
    await this.loadListTauxIPPs()
    await this.loadListAptitudesAvis()
    await this.searchTypesVisite({})
    await this.searchContextes(null);
    await this.searchRisques(null)

  }

  @Watch("search")
  storeSearchDossier() {
    store.state.listeDossier = this.search
  }

  updateSort(params: any){
    this.sortBy = params.sortBy
    this.isSortDirDesc = params.isSortDirDesc
    this.searchDossiers(params)
  }

  async loadListSituationsTravail() {
    await this.$http.ressifnet.situationTravails.paginatedList(1, 50).then(
      (response: PaginatedList<SituationTravail>) => {
        this.listSituationsTravail = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des situations de travail",
        });
      }
    );
  }

  async loadListCategoriesInvalidites() {
    await this.$http.ressifnet.categoriesInvalidites
      .paginatedList(1, 50, true)
      .then(
        (response: PaginatedList<CategorieInvalidite>) => {
          this.listCategorieInvalidites = response;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des catégories d'invalidite",
          });
        }
      );
  }

  async loadListTauxIPPs() {
    await this.$http.ressifnet.tauxIPPs.paginatedList(1, 50, true).then(
      (response: PaginatedList<TauxIPP>) => {
        this.listTauxIPP = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des taux IPP",
        });
      }
    );
  }

  async loadListAptitudesAvis() {
    await this.$http.ressifnet.aptitudesAvis.paginatedList(1, 50, true).then(
      (response: PaginatedList<AptitudeAvis>) => {
        this.listAptitudesAvis = response;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des aptitudes avis",
        });
      }
    );
  }

  async searchTypesVisite(params?: any) {
    if (!params || params?.reset) {
      this.listTypesVisite = new PaginatedList();
    }

    await this.$http.ressifnet.typesVisite
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<TypeVisite>) => {
        this.listTypesVisite.items = this.listTypesVisite.items!.concat(response.items.filter(ri => !this.listTypesVisite.items!.some(lsi => lsi.id == ri.id)));
        this.listTypesVisite.totalCount = response.totalCount
      },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des types de visite",
          });
        });
  }

  async searchContextes(params?: any) {
    if (!params || params?.reset) {
      this.listContextes = new PaginatedList();
    }

    await this.$http.ressifnet.contextesEntreprise
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<ContexteEntreprise>) => {
          this.listContextes.items = this.listContextes.items!.concat(
            response.items.filter(
              (ri) => !this.listContextes.items!.some((lsi) => lsi.id == ri.id)
            )
          );
          this.listContextes.totalCount = response.totalCount
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des contextes",
          });
        }
      );
  }

  async searchRisques(params?: any) {
    if (!params || params?.reset) {
      this.listRisques = new PaginatedList();
    }

    await this.$http.ressifnet.risques
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then(
        (response: PaginatedList<Risque>) => {
          this.listRisques.items = this.listRisques.items!.concat(
            response.items.filter(
              (ri) => !this.listRisques.items!.some((lsi) => lsi.id == ri.id)
            )
          );
          this.listRisques.totalCount = response.totalCount
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des risques",
          });
        }
      );
  }

  askResetResearch() {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir réinitialiser vos filtres ?",
        {
          title: "Confirmation d'annulation",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.resetResearch();
        } else {
          return;
        }
      });
  }

  async resetResearch(){
    this.onClearEntreprise(this.search)
    this.search = {}
  }

  async searchDossiers(params: any) {
    store.state.listeDossier = this.search;
    this.searchLoading = true;
    if (this.search.sexe?.length) {
      this.search.filterSexe = true;
    } else {
      this.search.filterSexe = false;
    }
    await this.$http.ressifnet.dossiers
      .search({
        ...this.search,
        ageMinimum: this.search.tranchesAge?.minimum ?? null,
        ageMaximum: this.search.tranchesAge?.maximum ?? null,
        ancienneteMinimum: this.search.anciennete?.minimum ?? null,
        ancienneteMaximum: this.search.anciennete?.maximum ?? null,
        pageNumber: params.pageIndex,
        pageSize: this.pageSize,
        orderBy: params.sortBy,
        orderByDescending: params.isSortDirDesc
      })
      .then((response: PaginatedList<any>) => {
        this.searchResult = response;
        this.searchLoading = false;
        setTimeout(() => {
          let formCard = (this.$refs['searchDossierTable'] as any);
          if (formCard)
            formCard.scrollIntoView({ behavior: 'smooth' })
        }, 350)
      });
  }

  async searchEntreprises(params: any) {
    if (this.search.entrepriseId == null || this.search.entrepriseId == undefined){
      if (!params || params?.reset) {
        this.listeEntreprises = this.listeEntreprises?.filter(e => e.id == this.search.entrepriseId);
        this.totalCountEntreprise = this.listeEntreprises.length;
      }
      
      !params.onCreate ?? this.onClearSite(this.search) 

      await this.$http.ressifnet.entreprises
        .paginatedList(
          params?.pageNumber ?? 1, 
          params?.pageSize ?? 10, 
          params?.search ?? "",
          "",
          params?.onCreate ? this.search.entrepriseId : ""
        )
        .then((res: PaginatedList<Entreprise>) => {
          this.totalCountEntreprise = res.totalCount
          this.listeEntreprises = this.listeEntreprises!.concat(res.items.filter(ri => !this.listeEntreprises!.some(lsi => lsi.id == ri.id)))
          if (this.listeEntreprises.length === 1){
            this.search.entrepriseId = this.listeEntreprises[0].id;
            this.onEntrepriseSelected(this.search.entrepriseId)
          }
          this.refreshEntrepriseCollapseTitle()
          if(this.search.entrepriseId != undefined) this.searchSites(null);
        });
    }
  }

  async searchSites(params: any) {
    if (this.search.siteId == null || this.search.siteId == undefined){
      if (!params || params?.reset) {
        this.listeSites = this.listeSites?.filter(e => e.id == this.search.siteId);
        this.totalCountSite = this.listeSites.length;
      }

      await this.$http.ressifnet.sites
        .paginatedList(
          this.search.entrepriseId, 
          params?.pageNumber ?? 1, 
          params?.pageSize ?? 10, 
          params?.search ?? "",
          "",
          "", 
          this.search.regionId, 
          this.search.brancheId, 
          params?.onCreate ? this.search.siteId : "")
        .then((res: PaginatedList<Site>) => {
          this.totalCountSite = res.totalCount
          this.listeSites = this.listeSites!.concat(res.items.filter(ri => !this.listeSites!.some(lsi => lsi.id == ri.id)))
        })
        if (this.listeSites.length === 1){
          this.search.siteId = this.listeSites[0].id
          this.searchRegions({});
          this.searchBranches({});
          this.searchSecteursWithSite({});
          this.onSiteSelected(this.search.siteId);
        }
        this.refreshEntrepriseCollapseTitle()
        if (this.search.siteId != undefined) this.searchSecteurs(null);
    }
  }

  async searchSitesWithRegion(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(e => e.id == this.search.siteId);
      this.totalCountSite = this.listeSites.length
    }

    await this.$http.ressifnet.sites
      .paginatedListByRegionId( this.search.entrepriseId, this.search.regionId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search ?? "", this.search.regionId, this.search.brancheId)
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount
        this.listeSites = this.listeSites!.concat(res.items.filter(ri => !this.listeSites!.some(lsi => lsi.id == ri.id)))
      })
  }

  async searchSitesWithBranche(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(e => e.id == this.search.siteId);
      this.totalCountSite = this.listeSites.length
    }

    await this.$http.ressifnet.sites
      .paginatedListByBrancheId(this.search.entrepriseId, this.search.brancheId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search ?? "", this.search.regionId, this.search.brancheId)
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount
        this.listeSites = this.listeSites!.concat(res.items.filter(ri => !this.listeSites!.some(lsi => lsi.id == ri.id)))
      })
  }

  async searchSecteurs(params: any) {
    if (this.search.secteurId == null || this.search.secteurId == undefined) {
      if (!params || params?.reset) {
        this.listeSecteur = this.listeSecteur?.filter(e => e.id == this.search.secteurId);
        this.totalCountSecteur = this.listeSecteur.length;
      }
      if (this.search.entrepriseId != null || this.search.entrepriseId != undefined){
        await this.$http.ressifnet.secteurs
        .paginatedList(
          this.search.entrepriseId, 
          params?.pageNumber ?? 1, 
          params?.pageSize ?? 10, 
          params?.search, 
          "", 
          "", 
          "", 
          "", 
          params?.onCreate ? this.search.secteurId : "")
        .then((res: PaginatedList<Secteur>) => {
          this.totalCountSecteur = res.totalCount
          this.listeSecteur = this.listeSecteur!.concat(res.items.filter(ri => !this.listeSecteur!.some((lsi: any) => lsi.id == ri.id)))
          this.listeSecteur.map(x => this.listeSecteur.forEach(x => x.completeLabel = x.libelle + " (" + x.siteLibelle + ")"))
          if (this.listeSecteur.length === 1){
            this.search.secteurId = this.listeSecteur[0].id
            this.searchRegions({});
            this.searchBranches({});
          }
          this.refreshEntrepriseCollapseTitle()
        })
      }
    }
  }

  async searchSecteursWithSite(params: any) {
    if (!params || params?.reset){
      this.listeSecteur = this.listeSecteur?.filter(e => e.id == this.search.secteurId);
      this.totalCountSecteur = this.listeSecteur.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedListForSite(this.search.entrepriseId, this.search.siteId, params?.pageNumber ?? 1, params?.pageSize ?? 10, params?.search)
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSecteur = res.totalCount
        this.listeSecteur = this.listeSecteur!.concat(res.items.filter(ri => !this.listeSecteur!.some((lsi: any) => lsi.id == ri.id)))
        this.listeSecteur.map(x => this.listeSecteur.forEach(x => x.completeLabel = x.libelle + " (" + x.siteLibelle + ")"))
      })
  }

  async searchBranches(params: any) {
    if (!params || params?.reset) {
      this.listeBranches = this.listeBranches?.filter(e => e.id == this.search.brancheId);
      this.totalCountBranche = this.listeBranches.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        this.search.entrepriseId, 
        params?.pageNumber ?? 1, 
        params?.pageSize ?? 10, 
        params?.search,
        params?.onCreate ? this.search.brancheId : "")
      .then((res: PaginatedList<Branche>) => {
        this.totalCountBranche = res.totalCount
        this.listeBranches = this.listeBranches!.concat(res.items.filter(ri => !this.listeBranches!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listeRegions = this.listeRegions?.filter(e => e.id == this.search.regionId);
      this.totalCountRegion = this.listeRegions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.search.entrepriseId, 
        params?.pageNumber ?? 1, 
        params?.pageSize ?? 10, 
        params?.search, 
        params?.onCreate ? this.search.regionId : "")
      .then((res: PaginatedList<Region>) => {
        this.totalCountRegion = res.totalCount
        this.listeRegions = this.listeRegions!.concat(res.items.filter(ri => !this.listeRegions!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  async searchTags(params: any) {
    if (!params || params?.reset) {
      this.listeTags = this.listeTags?.filter(e => this.search.tagsId?.some(id => id == e.id));
      this.totalCountTag = this.listeTags.length;
    }

    await this.$http.ressifnet.tags
      .paginatedList(
        this.search.entrepriseId, 
        params?.pageNumber ?? 1, 
        params?.pageSize ?? 10, 
        params?.search, 
        params?.onCreate ? this.search.tagsId : "")
      .then((res: PaginatedList<Tag>) => {
        this.totalCountTag = res.totalCount
        this.listeTags = this.listeTags!.concat(res.items.filter(ri => !this.listeTags!.some(lsi => lsi.id == ri.id)))
      }) 
  }

  // async searchReferent(params: any) {
  //   if (!params || params?.reset) {
  //     this.searchReferentsItems = this.searchReferentsItems?.filter(e => e.id == this.search.referentId);
  //     this.searchReferentsTotalCount = this.searchReferentsItems.length;
  //   }

  //   await this.$http.ressifnet.dossiers
  //     .paginatedListReferents(
  //       params?.pageNumber ?? 1,
  //       params?.pageSize ?? 10,
  //       params?.search ?? ""
  //     )
  //     .then(
  //       (response: PaginatedList<UserDetail>) => {
  //         this.searchReferentsTotalCount = response.totalCount;
  //         this.searchReferentsItems = this.searchReferentsItems!.concat(response.items.filter(ri => !this.searchReferentsItems!.some(lsi => lsi.id == ri.id)))
  //         // this.search.referentId = this.searchReferentsItems.length === 1 ? this.searchReferentsItems[0].id : undefined;
  //       },
  //       (error: any) => {
  //         errorAlert.fire({
  //           title: "Erreur lors du chargement des réferents possibles",
  //         });
  //       }
  //     );
  // }

  onEntrepriseSelected(val: any) {
    if (val) {
      (this.$refs['siteSearchSelect'] as any).resetParams()
      const entreprise = this.listeEntreprises?.filter(s => s.id == val)[0]
      this.search.siteId = undefined
      this.search.secteurId = undefined
      this.search.tagsId = undefined
      this.search.brancheId = undefined
      this.search.regionId = undefined
      this.listeSites = []
      this.listeSecteur = []
      this.listeBranches = []
      this.listeRegions = []
      this.listeTags = []
      this.brancheLibelle = entreprise?.site?.brancheLibelle ?? "Ce site n'a pas de branche"
      this.regionLibelle = entreprise?.site?.regionLibelle ?? "Ce site n'a pas de région"
      this.searchSites({});
      this.searchSecteurs({});
      this.searchRegions({});
      this.searchBranches({});
      this.searchTags({});
    }
  }


  onSiteSelected(val: any) {
    if (val) {
      const site = this.listeSites?.filter(s => s.id == val)[0]
      this.search.brancheId = undefined
      this.brancheLibelle = site?.brancheLibelle ?? "Ce site n'a pas de branche"
      this.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région"
    }
  }


  onClearEntreprise(item: DossierSearchParams) {
    item.entrepriseId = undefined
    this.onClearSite(this.search)
    this.onClearSecteur(this.search)
    this.onClearRegion(this.search)
    this.onClearBranche(this.search)
    this.onClearService(this.search)
    this.searchEntreprises({})    
  }

  onClearSite(item: DossierSearchParams) {
    item.siteId = undefined
    this.listeSites = []
    this.searchSites({})
    this.searchSecteurs({})

  }

  onClearSecteur(item: DossierSearchParams) {
    item.secteurId = undefined
    this.searchSecteurs({})
  }

  onClearRegion(item: DossierSearchParams){
    item.regionId = undefined
    this.searchRegions({})
    this.searchSites({})
  }

  onClearBranche(item: DossierSearchParams){
    item.brancheId = undefined
    this.searchBranches({})
    this.onClearSite(item)
    this.searchSites({})
  }

  onClearService(item: DossierSearchParams){
    item.tagsId = []
    this.listeTags = []
    this.totalCountTag = 0
  }
}
