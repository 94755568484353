






















































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { BSpinner, BPagination, BTable, BDropdown } from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import store from '@/store/index'

@Component({
  components: {
    BSpinner,
    BPagination,
    BTable,
    BDropdown,
  },
  name: "search-dossier-results",
})
export default class SearchDossierResults extends Vue {
  
  @Prop({ required: true, default: "Liste des dossiers " }) title!: string;
  @Prop({ required: true, default: null }) dossiers!: PaginatedList<any>;
  @Prop({ required: false }) loading!: boolean;
  @Prop({ required: false, default: 1 }) pageNumber!: number;
  @Prop({ required: false, default: 10 }) pageSize!: number;
  @Prop({ required: false}) totalCount!: number;
  @Prop({ required: false, default: false }) pagination!: boolean;
  @Prop({ required: false, default: "numeroDossier" }) sortBy!: string;
  @Prop({ required: false, default: false }) isSortDirDesc!: boolean;
  @Prop({ required: false, default: false }) sortable!: boolean;
  @Prop({ required: false, default: false }) displayReferent!: boolean;
  @Prop({ required: false}) searchParams: any;
  @Prop({ required: false, default: true }) exportable!: boolean;

  exportLoading = false;

  tableColumns = [
    { key: "numeroDossier", sortable: this.sortable, sortKey: "numeroDossier" },
    { key: "nom", sortable: this.sortable, sortKey: "nom" },
    { key: "prenom", sortable: this.sortable, sortKey: "prenom" },
    { key: "dateCreation", sortable: this.sortable, sortKey: "dateCreationDateTime" },
    {
      key: "dateNaissance",
      sortable: this.sortable,
      sortKey: "dateNaissanceDateTime",
    },
    {
      key: "Entreprise",
      sortable: true,
      sortKey: "entreprise",
    },       
    { key: "action" },
  ];

  get userPreferences() {
    return this.$store.state.user.user_preferences
  }
  get preference() {
    return this.userPreferences.find((value: any) => {
      return value.key == 'searchableColumns'
    });
  }

  created() {
    
    if(this.userPreferences){
      var columnsPreferences = this.preference;
      
      if(columnsPreferences){
        if(JSON.parse(columnsPreferences.value).length > 0){
          this.tableColumns = [...JSON.parse(columnsPreferences.value), { key: "action" }];
        } else {
          this.tableColumns = this.tableColumns;
        }
       
      }  
     
    }
  }

  @Watch("pageNumber")
  reloadList() {
    this.$emit("reload-list-dossiers", {
      pageIndex: this.pageNumber,
      sortBy: this.sortBy,
      isSortDirDesc: this.isSortDirDesc,
    });
  }

  async exportDossiers() {
    this.exportLoading = true;
    if (this.searchParams?.sexe?.length) {
      this.searchParams.filterSexe = true;
    } else {
      this.searchParams.filterSexe = false;
    }
    this.$http.ressifnet.dossiers
          .export({
            ...this.searchParams, 
            ageMinimum: this.searchParams.tranchesAge?.minimum ?? null,
            ageMaximum: this.searchParams.tranchesAge?.maximum ?? null,
             pageSize: this.pageSize,
             totalCount: this.dossiers.totalCount,
          }
            )
          .then((response: any) => {
            this.exportLoading = false;
            var blob = new Blob([response], { type: 'application/vnd.ms-excel' });
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            var date = new Date();
            link.setAttribute('download', "export_search_" + date.getDate() + '/' + (date.getMonth() + 1) + "/" + date.getFullYear() +".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        }

  sortChanged(ctx: any) {
    if (this.isSortDirDesc) {
      this.$emit("update-sort", {
        pageIndex: this.pageNumber,
        sortBy: ctx.sortBy,
        isSortDirDesc: false,
      });
    } else {
      this.$emit("update-sort", {
        pageIndex: this.pageNumber,
        sortBy: ctx.sortBy,
        isSortDirDesc: true,
      });
    }
  }

  viewDetails(dossierId: string) {
    this.$router.push({
      name: "details-accompagnement-individuel",
      params: { id: dossierId },
    });
  }
}
