export enum TypeConsommationEnum {
  Consomme_pas = "Ne consomme pas",
  Consommation_OMS = "Consommation OMS",
  Consommation_risque = "Consommation à risque"
}

export enum TypeConsommationSearchEnum {
  Consomme_pas = "Non",
  Consommation_OMS = "Consommation OMS",
  Consommation_risque = "A risque"
}